import type { ColumnDef, SortingFn } from "@tanstack/react-table";
import type { AxiosError } from "axios";
import axios from "axios";
import type { TFunction } from "i18next";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useAuthContext } from "../../../../../components/Auth";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  EditButton,
  PrimaryButtonWithPlusIcon,
} from "../../../../../components/Buttons/Buttons";
import { IconGroup } from "../../../../../components/DocumentCard/DocumentCard";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { Table } from "../../../../../components/Table/Table";
import { ToggleSwitch } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import { endpoints } from "../../../../../endpoints";
import type { UUID } from "../../../../../types/types";
import type {
  AttributeSchema,
  AttributeTemplateSchema,
  AttributeValue,
  CollectionPatchArgsSchema,
  CollectionRowSchema,
  CollectionSchema,
  IproductReferenceCollectionItem,
} from "../../../../../types/types.PIM";
import { useRoutePath } from "../../../../../util/Routing";
import {
  formatDate,
  TablePlaceholder,
  useStoreState,
} from "../../../../../util/util";
import { LinkAttributeValueSchema } from "../../../../../util/zod.util";
import { AddItemToTemplateCollectionForm } from "./AddItemToTemplateCollectionForm";

type TemplateCollectionItemsProps = {
  template: AttributeTemplateSchema | undefined;
  collection: CollectionSchema;
  onSuccess: () => void;
  isLoading: boolean;
  error: AxiosError | Error;
};

const Placeholder = (t: TFunction) => (
  <TablePlaceholder
    message={t("No items to show. Please click the button below to add items.")}
  />
);

function handleDefaultValues(attr: AttributeSchema, adminPath: string) {
  const val = attr?.default_values
    ? attr.input_type === "multi_select"
      ? attr?.default_values
      : attr?.default_values[0]?.value
    : undefined;
  if (attr.input_type === "multiline_entry") {
    return (
      <>
        {val ? (
          <div
            dangerouslySetInnerHTML={{
              __html: `<div class="ql-editor">${val as string}</div>` || "--",
            }}
          />
        ) : (
          "--"
        )}
      </>
    );
  } else if (attr.input_type === "toggle" || attr.input_type === "checkbox") {
    return (
      <>
        <ToggleSwitch
          label={""}
          name={attr.name}
          isChecked={val ? (val as boolean) : false}
          key={attr.id}
          disabled={true}
        />
      </>
    );
  } else if (attr.input_type === "date") {
    return val ? formatDate(new Date(val as string).toUTCString()) : "--";
  } else if (attr.input_type === "multi_select") {
    let list = val
      ? (val as unknown as AttributeValue[])?.map((val) => val.value)
      : [];
    const tooltipList = () => {
      return list.length > 1 ? (
        <ul style={{ listStyle: "inside", margin: 0, padding: 0 }}>
          {list.map((chip, index) => {
            return <li key={index}>{chip}</li>;
          })}
        </ul>
      ) : null;
    };
    const valueText = () => {
      if (val as unknown as AttributeValue[]) {
        if (list.length === 0) {
          return `--`;
        } else if (list.length < 3) {
          return list.join(", ");
        } else {
          return `${list[0]}, ${list[1]} and ${list.length - 2} more`;
        }
      }
      return "--";
    };
    return (
      <>
        <span data-for={`row-tooltip-${attr.id}`} data-tip={""}>
          {valueText()}
        </span>
        {list.length > 1 && (
          <ReactTooltip
            id={`row-tooltip-${attr.id}`}
            place="top"
            data-html={true}
            effect="solid"
            backgroundColor="#60676f"
            multiline={true}
          >
            {tooltipList()}
          </ReactTooltip>
        )}
      </>
    );
  } else if (attr.input_type === "link") {
    const parsed = LinkAttributeValueSchema.safeParse(
      attr?.default_values?.[0]?.value
    );
    if (parsed.success && parsed.data.url) {
      return (
        <a target={"_blank"} rel="noreferrer" href={parsed.data.url}>
          {parsed.data.display_text}
        </a>
      );
    } else if (parsed.success && parsed?.data?.display_text) {
      return parsed.data.display_text;
    } else return "--";
  } else if (attr.input_type === "product_reference") {
    return attr?.default_values?.[0]?.value ? (
      <Link
        to={`${adminPath}/pim/products/${
          (attr?.default_values?.[0]?.value as IproductReferenceCollectionItem)
            .id
        }`}
      >
        {
          (attr?.default_values?.[0]?.value as IproductReferenceCollectionItem)
            .name
        }
      </Link>
    ) : (
      "--"
    );
  }

  if (!val) {
    return "--";
  } else return String(val);
}

type TableRow = { [prop: string]: unknown; uuid: string };

export function TemplateCollectionItems({
  template,
  collection,
  onSuccess,
  isLoading,
  error,
}: TemplateCollectionItemsProps) {
  const { t } = useTranslation();
  const { id: collection_id } =
    useParams<{ id: string; template_id?: string }>();
  const [showAddItemForm, setShowAddItemForm] = useState(false);
  const { notifyError, notifySuccess } = useContext(Notifications);
  const { tenant_id } = useStoreState();
  const { hasPermission } = useAuthContext();
  const { adminPath } = useRoutePath();

  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [rowToEdit, setRowToEdit] = useState<UUID>("");

  const handleAttributesReorder = async (tableData: Array<any>) => {
    const reorderList = tableData.map((row) => row.uuid);

    try {
      await axios.post(
        `/v2/tenants/${tenant_id}/pim/collections/${collection_id}/priority?type=collection_row`,
        {
          items: reorderList,
        }
      );
      notifySuccess(t("Your changes have been saved successfully"));
    } catch (error) {
      notifyError(t("Something went wrong, please try again"), { error });
      collection &&
        setTableData(
          collection.rows.reduce<TableRow[]>((acc, row) => {
            const temp = row.attributes.reduce(
              (acc, attr) => {
                return {
                  ...acc,
                  [attr.name]: handleDefaultValues(attr, adminPath),
                  uuid: row.id,
                };
              },
              { uuid: row.id } as TableRow
            );
            return (acc = [...acc, temp]);
          }, [])
        );
    }
  };

  const sortingFn: SortingFn<TableRow> = (rowA, rowB, _columnId) => {
    const valueA =
      rowA.getValue(_columnId) === undefined
        ? 0
        : (rowA.getValue(_columnId) as { name: string }[])[0]?.name ??
          (rowA.getValue(_columnId) as { display_text: string; url: string })
            .display_text ??
          rowA.getValue(_columnId);
    const valueB =
      rowB.getValue(_columnId) === undefined
        ? 0
        : (rowB.getValue(_columnId) as { name: string }[])[0]?.name ??
          (rowB.getValue(_columnId) as { display_text: string; url: string })
            .display_text ??
          rowB.getValue(_columnId);
    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    if (collection.rows) {
      setTableData(
        collection.rows.reduce<TableRow[]>((acc, row) => {
          const temp = row.attributes.reduce(
            (acc, attr) => {
              return {
                ...acc,
                [attr.name]: handleDefaultValues(attr, adminPath),
                uuid: row.id,
              };
              // }
            },
            { uuid: row.id } as TableRow
          );
          return (acc = [...acc, temp]);
        }, [])
      );
    } else {
      setTableData([]);
    }
  }, [collection, setTableData, adminPath]);

  const tableColumns = useMemo<ColumnDef<TableRow>[]>(() => {
    const handleRemoveRowFromCollection = async (uuid: UUID) => {
      try {
        await axios.patch<CollectionPatchArgsSchema, CollectionSchema>(
          endpoints.v2_tenants_id_pim_collections_id(tenant_id, collection_id),
          { rows_to_delete: [uuid] }
        );
        notifySuccess(t("Item deleted successfully"));
        onSuccess();
      } catch (error) {
        const errorMessage = (error as AxiosError)?.response?.data?.message;
        notifyError(
          errorMessage
            ? errorMessage
            : t("Failed to remove row from collection"),
          {
            error,
          }
        );
      }
    };

    return collection.columns.reduce<
      {
        header: string | ((props?: any) => JSX.Element);
        accessorKey: string;
        cell?: string | ((props?: any) => JSX.Element);
        enableSorting?: boolean;
        disableResize?: boolean;
        sortingFn?: SortingFn<TableRow>;
      }[]
    >((acc, col, index) => {
      if (index === collection.columns.length - 1) {
        acc.push({
          header: col.name,
          accessorKey: col.name,
          sortingFn: sortingFn,
          cell: (cell) => cell.renderValue(),
        });

        acc.push({
          // The empty header and accessor are needed so it doesn't crash.
          header: "",
          enableSorting: false,
          accessorKey: " ",
          disableResize: true,
          cell: (cell) => (
            <IconGroup style={{ justifyContent: "flex-end" }}>
              {hasPermission("delete_templates") ? (
                <ButtonWithConfirmDialog
                  Button={(props) => <DeleteButton {...props} />}
                  testid={"remove-row-from-collection-in-template"}
                  handleConfirm={() =>
                    handleRemoveRowFromCollection(cell.row.original.uuid)
                  }
                  confirmMessage={t(
                    "Are you sure you want to delete this row?"
                  )}
                />
              ) : null}
              {hasPermission("modify_templates") ? (
                <EditButton
                  onClick={() => {
                    setShowAddItemForm(true);
                    setRowToEdit(cell.row.original.uuid);
                  }}
                />
              ) : null}
            </IconGroup>
          ),
        });
      } else {
        if (col.name.toLocaleLowerCase().includes("description")) {
          acc.push({
            header: col.name,
            accessorKey: col.name,
            cell: (cell) => {
              return (
                <div
                  data-for={`desc-${index}-tooltip`}
                  data-tip={cell.getValue()}
                >
                  {cell.getValue()}
                  <ReactTooltip
                    id={`desc-${index}-tooltip`}
                    place="top"
                    effect="solid"
                    multiline={true}
                  />
                </div>
              );
            },
          });
        } else {
          acc.push({
            header: col.name,
            accessorKey: col.name,
            sortingFn: sortingFn,
            cell: (cell) => cell.renderValue(),
          });
        }
      }
      return acc;
    }, []);
  }, [
    collection,
    collection_id,
    notifyError,
    notifySuccess,
    onSuccess,
    hasPermission,
    t,
    tenant_id,
  ]);

  return (
    <>
      <div style={{ marginBottom: "24px" }}>
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={isLoading}
          error={error}
          Placeholder={Placeholder(t)}
          showReorderControls={true}
          enableDefaultSort={true}
          enableColumnResize={true}
          handleTableReorder={handleAttributesReorder}
          reorderConfirmationMessage={
            template?.number_of_products && template?.number_of_products > 0
              ? t(
                  `{{numberOfProducts}} {{product}} will get affected by this change, Are you sure you want to confirm changes?`,
                  {
                    numberOfProducts: template.number_of_products,
                    product:
                      template.number_of_products === 1
                        ? "product"
                        : "products",
                  }
                )
              : undefined
          }
        />
      </div>
      {hasPermission("modify_templates") && (
        <PrimaryButtonWithPlusIcon onClick={() => setShowAddItemForm(true)}>
          {t("Add item")}
        </PrimaryButtonWithPlusIcon>
      )}
      <SlideOut
        show={showAddItemForm}
        closeFlyout={() => {
          setShowAddItemForm(false);
          setRowToEdit("");
        }}
      >
        <AddItemToTemplateCollectionForm
          collectionID={collection_id}
          onSuccess={() => {
            setShowAddItemForm(false);
            setRowToEdit("");
            onSuccess();
          }}
          row={
            rowToEdit
              ? collection.rows &&
                (collection.rows.find(
                  (row) => row.id === rowToEdit
                ) as CollectionRowSchema)
              : null
          }
          columns={collection.columns}
        />
      </SlideOut>
    </>
  );
}
