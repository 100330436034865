import styled from "styled-components";
import { ExternalLinksTable } from "./ExternalLinks/ExternalLinksTable";
import type { PIMProduct } from "../../../../types/types.PIM";
import type { DataMutate } from "../../../../types/types";
import { DocumentsOrDigitalMediaTable } from "./DocumentsOrDigitalMediaTable/DocumentsOrDigitalMediaTable";
import { PortfolioAssets } from "./PortfolioAssets/PortfolioAssets";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const AssetsContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div:last-child {
    border-bottom: none;
  }
`;

type TableType = {
  title: "Documents" | "Digital Media";
  // tableData: Assets[];
  // pagination?: IPagination;
};

export const ProductAssets = ({
  product,
  replaceProductId,
  fetchProductData,
  viewMode = false,
}: {
  product: PIMProduct;
  fetchProductData: DataMutate<PIMProduct>;
  replaceProductId: (id: string) => void;
  viewMode?: boolean;
}) => {
  const { t } = useTranslation();

  const [tables, setTables] = useState<TableType[]>([
    {
      title: t("Documents"),
      // tableData: docAssets?.data ?? [],
      // pagination: docAssets?.pagination,
    },
    {
      title: t("Digital Media"),
      // tableData: digitalMediaAssets?.data ?? [],
      // pagination: digitalMediaAssets?.pagination,
    },
  ]);

  useEffect(() => {
    setTables([
      {
        title: t("Documents"),
      },
      {
        title: t("Digital Media"),
      },
    ]);
  }, [t]);

  if (viewMode) {
    return (
      <AssetsContainer>
        <PortfolioAssets
          externalLinkAssets={product.asset_external_links}
          fetchProductData={fetchProductData}
          product={product}
        />
      </AssetsContainer>
    );
  } else {
    return (
      <AssetsContainer>
        {tables?.map(({ title }) => (
          <DocumentsOrDigitalMediaTable
            fetchProductData={fetchProductData}
            replaceProductId={replaceProductId}
            title={title}
            key={title}
            product={product}
          />
        ))}
        <ExternalLinksTable
          external_links={product.asset_external_links}
          fetchProductData={fetchProductData}
          product={product}
        />
      </AssetsContainer>
    );
  }
};
