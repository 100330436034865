import styled from "styled-components/macro";
import type {
  AssetsExternalLinks,
  PIMProduct,
} from "../../../../../types/types.PIM";
import { ExternalLinksPortfolioAssets } from "./ExternalLinksPortfolioAssets";
import type { DataMutate } from "../../../../../types/types";
import { DocumentsOrDigitalMediaTable } from "../DocumentsOrDigitalMediaTable/DocumentsOrDigitalMediaTable";
import { t } from "i18next";

const PortfolioAssetsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PortfolioAssets = ({
  externalLinkAssets,
  product,
  fetchProductData,
}: {
  externalLinkAssets: AssetsExternalLinks[];
  product: PIMProduct;
  fetchProductData: DataMutate<PIMProduct>;
}) => {
  return (
    <PortfolioAssetsContainer>
      {/* <DocumentPortfolioAssets /> */}
      <DocumentsOrDigitalMediaTable
        fetchProductData={fetchProductData}
        title={t("Documents")}
        key={"Documents"}
        product={product}
        replaceProductId={() => Promise.resolve()}
        isOutsideLogin={true}
      />
      {/* very confusing, but this actually only displays one or the other based on the title */}
      <DocumentsOrDigitalMediaTable
        fetchProductData={fetchProductData}
        title={t("Digital Media")}
        key={"Digital Media"}
        product={product}
        replaceProductId={() => Promise.resolve()}
        isOutsideLogin={true}
      />
      {externalLinkAssets.length > 0 && (
        <ExternalLinksPortfolioAssets links={externalLinkAssets} />
      )}
    </PortfolioAssetsContainer>
  );
};
