import { zodResolver } from "@hookform/resolvers/zod";
import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import { useContext, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import {
  GreyLine,
  GreyLineWrapper,
  MultiSelectField,
} from "../../../../../components/AttributeGroupFormGenerator/AttributeGroupFormGenerator";
import { PrimaryButtonFitContainer } from "../../../../../components/Buttons/Buttons";
import { DatePicker } from "../../../../../components/DatePicker/DatePicker";
import { SectionTitle } from "../../../../../components/Form/Form";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { RichEditor } from "../../../../../components/RichEditor/RichEditor";
import { SelectBoxV2 } from "../../../../../components/SelectBoxV2/SelectBoxV2";
import { TextField } from "../../../../../components/TextFields/TextFields";
import { ToggleSwitch } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import { Form } from "../../../../../layout/FormLayout";
import type { OptionType, UUID } from "../../../../../types/types";
import type {
  AttributeSchema,
  CollectionRowSchema,
  IproductReferenceCollectionItem,
  PIMProduct,
} from "../../../../../types/types.PIM";
import { useFormWrapper, useStoreState } from "../../../../../util/util";
import { getValidationForProductCollection } from "../components/ProductCollectionSchemaTable/ProductCollectionSchema.util";
import { MarginBottomHeaderLeft } from "../SellerAdminPIMAttributes/CreateAttribute";
import { LinkAttributeValueSchema } from "../../../../../util/zod.util";
import { SearchSelectInfiniteScroll } from "../../../../../components/SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import React from "react";
import { endpoints } from "../../../../../endpoints";

export function stringArrayToOptionArray(str: string): OptionType<string> {
  return { label: str, value: str };
}

function handleFormValues(
  val:
    | string
    | boolean
    | OptionType<string | null>
    | { display_text: string; url: string }
    | string[]
    | undefined
) {
  if (typeof val === "string") {
    return val ? [val] : null;
  } else if (val === undefined) {
    return null;
  } else if (typeof val === "boolean") {
    return [val];
  } else if (Array.isArray(val)) {
    return val;
  } else if (typeof val === "object") {
    const parsedLink = LinkAttributeValueSchema.safeParse(val);
    const parsedOptionType = z
      .object({
        value: z.union([z.string(), z.boolean()]),
        label: z.string(),
      })
      .safeParse(val);
    if (parsedLink.success) {
      return parsedLink.data.url ? [parsedLink.data] : null;
    }
    if (parsedOptionType.success) {
      return (val as OptionType)?.value ? [(val as OptionType).value] : null;
    }
  } else if (val === null) {
    return null;
  }
}

type FormOutput = {
  [key: string]: string | OptionType<string> | boolean;
};

const getCustomValidation = ({
  input_type,
  optional,
  t,
}: {
  input_type: AttributeSchema["input_type"];
  optional: boolean;
  t: TFunction;
}) => {
  if (input_type === "multi_select") {
    return optional ? z.string().array().optional() : z.string().array();
  } else {
    return getValidationForProductCollection({ input_type, optional, t });
  }
};

const getValidationSchema = (columns: AttributeSchema[], t: TFunction) => {
  let validationSchemaObj: {
    [prop: string]:
      | z.ZodString
      | z.ZodBoolean
      | z.ZodSchema
      | z.ZodOptional<z.ZodString | z.ZodBoolean | z.ZodSchema>;
  } = {};

  columns.forEach((col) => {
    validationSchemaObj = {
      ...validationSchemaObj,
      [col.name]: getCustomValidation({
        input_type: col.input_type,
        optional: true,
        t,
      }),
    };
  });
  return z.object(validationSchemaObj).superRefine((formValue, ctx) => {
    if (
      Object.values(formValue).every(
        (value: string | boolean | string[] | OptionType<any> | undefined) => {
          if (typeof value === "string") {
            return value === "";
          } else if (Array.isArray(value)) {
            return value.length === 0;
          } else if (typeof value === "object") {
            return !value;
          } else {
            return value === undefined || value === null;
          }
        }
      )
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t("Select at least one field"),
        path: [],
      });
    }
  });
};

export function AddItemToTemplateCollectionForm({
  onSuccess,
  collectionID,
  columns,
  row,
}: {
  onSuccess: () => void;
  collectionID: UUID;
  columns: AttributeSchema[];
  row: CollectionRowSchema | null;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { tenant_id, storefront_id } = useStoreState();
  const multiSelectRefs = useRef<{ [key: string]: { clearChips: () => void } }>(
    {}
  );

  const defaultValues =
    row?.attributes.reduce((acc, row) => {
      const has_value = !!row.default_values && !!row.default_values[0]?.value;
      if (row.input_type === "link") {
        acc[row.name] = has_value
          ? (row.default_values![0]?.value as {
              url: string;
              display_text: string;
            })
          : { display_text: "", url: "" };
      } else if (row.input_type === "toggle") {
        acc[row.name] = has_value
          ? (row.default_values![0]?.value as boolean)
          : false;
      } else if (row.input_type === "single_select") {
        acc[row.name] = {
          // This string coercion shouldn't be needed but typescript believes it
          // can still be a boolean here.
          label: has_value
            ? String(row.default_values![0]?.enum_variant?.variant)
            : "",
          value: has_value ? row.default_values![0]?.enum_variant?.variant : "",
        };
      } else if (row.input_type === "product_reference") {
        acc[row.name] = {
          label: has_value
            ? (row.default_values![0]?.value as IproductReferenceCollectionItem)
                .name
            : "",
          value: has_value
            ? (row.default_values![0]?.value as IproductReferenceCollectionItem)
                .id
            : "",
        };
      } else if (row.input_type === "multi_select") {
        acc[row.name] =
          row.default_values?.map((val) => val.value as string) || [];
      } else {
        acc[row.name] = String(has_value ? row.default_values![0]?.value : "");
      }

      return acc;
    }, {} as { [key: string]: OptionType | string | boolean | undefined | string[] | { display_text: string; url: string } }) ??
    columns.reduce((acc, col) => {
      switch (col.input_type) {
        case "checkbox":
        case "toggle":
          acc[col.name] = false;
          break;
        case "single_select":
          acc[col.name] = { label: "", value: "" };
          break;
        case "product_reference":
          acc[col.name] = { label: "", value: "" };
          break;
        case "multi_select":
          acc[col.name] = [];
          break;
        case "link":
          acc[col.name] = { display_text: "", url: "" };
          break;
        default:
          acc[col.name] = "";
      }
      return acc;
    }, {} as { [key: string]: OptionType | string | boolean | undefined | string[] | { display_text: string; url: string } });

  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(getValidationSchema(columns, t)),
    defaultValues: defaultValues,
    // mode: "onChange",
  });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState,
    errors,
    watch,
  } = methodsOfUseForm;

  const { notifyError, notifySuccess } = useContext(Notifications);

  const createForm = (columns: AttributeSchema[]) => {
    // eslint-disable-next-line array-callback-return
    return columns.map((col) => {
      if (col.input_type === "form_field") {
        return (
          <TextField
            name={col.name}
            label={col.name}
            theref={register({
              required: false,
            })}
            formState={formState}
            errors={errors}
            type="text"
            key={col.id}
            readOnly={!col.is_editable}
          />
        );
      } else if (col.input_type === "single_select") {
        const choices = col?.choices?.map(stringArrayToOptionArray) ?? [];
        return (
          <Controller
            as={SelectBoxV2}
            control={control}
            name={col.name}
            isClearable
            disabled={!col.is_editable}
            placeholder={col.name}
            options={choices}
            rules={{
              required: false,
            }}
            errors={errors}
            formState={formState}
            key={col.id}
          />
        );
      } else if (col.input_type === "multi_select") {
        return (
          <MultiSelectField
            type={"collection"}
            attribute={
              row?.attributes.find((attr) => attr.name === col.name) || col
            }
            methodsOfUseForm={methodsOfUseForm}
            key={col.id}
            displayByName={true}
            multiSelectRefs={multiSelectRefs}
          />
        );
      } else if (col.input_type === "numeric") {
        return (
          <TextField
            name={col.name}
            label={`${col.name} (number)`}
            theref={register({
              required: false,
            })}
            formState={formState}
            errors={errors}
            type="number"
            key={col.id}
            readOnly={!col.is_editable}
          />
        );
      } else if (col.input_type === "multiline_entry") {
        return (
          <RichEditor
            value={(defaultValues?.[col.name] as string) ?? ""}
            id={col.id}
            name={col.name}
            label={col.name ?? (col.display_name ? t([col.display_name]) : "")}
            useFormMethods={{ register, formState, setValue, errors, watch }}
            key={col.id}
            readOnly={!col.is_editable}
          />
        );
      } else if (col.input_type === "date") {
        return (
          <DatePicker
            label={col.name ?? (col.display_name ? t([col.display_name]) : "")}
            name={col.name}
            defaultValue={defaultValues?.[col.name] as string}
            isOutsideRange={() => false}
            required={false}
            methodsOfUseForm={methodsOfUseForm}
            shouldValidate={true}
            key={col.id}
            disabled={!col.is_editable}
          />
        );
      } else if (col.input_type === "toggle" || col.input_type === "checkbox") {
        return (
          <ToggleSwitch
            label={col.name ?? (col.display_name ? t([col.display_name]) : "")}
            name={col.name}
            theref={register({ required: false })}
            key={col.id}
            disabled={!col.is_editable}
          />
        );
      } else if (col.input_type === "product_reference") {
        return (
          <SearchSelectInfiniteScroll
            name={col.name}
            key={col.id}
            errors={errors}
            formState={formState}
            defaultValue={defaultValues?.[col.name] ?? { label: "", value: "" }}
            isClearable
            disabled={!col.is_editable}
            placeholder={col.display_name ? t([col.display_name]) : ""}
            baseUrl={endpoints.v2_storefronts_id_pim_products(storefront_id)}
            theref={register({ name: col.name, required: col.is_required })}
            params={(() => {
              const params = new URLSearchParams();
              params.append("order_by", "asc");
              params.append("status", "published");
              return params;
            })()}
            getOptions={(response: PIMProduct[]) =>
              response.reduce(
                (
                  prev: { label: string; value: string }[],
                  { name, id }: PIMProduct
                ) => [...prev, { label: name, value: id }],
                []
              )
            }
            onChange={(data: any) => setValue(col.name, data)}
            testid={col.name}
          />
        );
      } else if (col.input_type === "link") {
        const maybeDefaultValue = (() => {
          if (defaultValues?.[col.name]) {
            const parsed = LinkAttributeValueSchema.safeParse(
              defaultValues?.[col.name]
            );
            if (parsed.success) {
              return parsed.data;
            }
          }
          return { url: undefined, display_text: undefined };
        })();

        const link = watch(col.name);

        const displayTextIsRequired = !!(link?.url || col.is_required);

        return (
          <Controller
            control={control}
            key={col.id}
            name={col.name}
            defaultValue={maybeDefaultValue}
            render={({ onChange, value: formValue }) => (
              <>
                <div style={{ marginBottom: "0px" }}>
                  <TextField
                    label={`${
                      col.display_name ? t([col.display_name]) : col.name
                    } - ${t("Display Text")}`}
                    name={"display_text"}
                    type={"text"}
                    theref={register({ required: displayTextIsRequired })}
                    formState={formState}
                    errors={errors?.[col.name] ?? {}}
                    defaultValue={maybeDefaultValue.display_text}
                    value={formValue?.display_text ?? ""}
                    onChange={(event) =>
                      onChange({
                        ...formValue,
                        display_text: event.target.value,
                      })
                    }
                    readOnly={!col.is_editable}
                  />
                </div>
                <GreyLineWrapper
                  style={{ justifyContent: "center", marginBottom: "0px" }}
                >
                  <GreyLine
                    style={{ height: "15px", width: "1px", marginBottom: "0" }}
                  />
                </GreyLineWrapper>

                <TextField
                  name={"url"}
                  type={"url"}
                  label={`${
                    col.display_name ? t([col.display_name]) : col.name
                  } - URL`}
                  formState={formState}
                  errors={errors?.[col.name] ?? {}}
                  defaultValue={maybeDefaultValue.url}
                  value={formValue?.url ?? ""}
                  onChange={(event) =>
                    onChange({ ...formValue, url: event.target.value })
                  }
                  // URL is never required for some reason
                  theref={register({ required: false })}
                  readOnly={!col.is_editable}
                />
              </>
            )}
          />
        );
      }
    });
  };

  const onSubmit = async (values: FormOutput) => {
    // the order of the object keys after submission is not deterministic, in
    // order for the API call to succeed the rows must be in the same order as
    // the columns.
    setIsLoading(true);
    const formOutputInCorrectOrder = columns.reduce((acc, col) => {
      if (col.name in values) {
        acc[col.name] = values[col.name];
      }
      return acc;
    }, {} as FormOutput);
    const formattedValues = Object.values(formOutputInCorrectOrder).map(
      (val) => {
        return { default_values: handleFormValues(val) };
      }
    );

    const rowsToPatch = {
      rows: [formattedValues],
    };

    const indexesOfNonSupportedValues: number[] = [];

    for (let i = 0; i < columns.length; i++) {
      if (
        columns[i].input_type !== "form_field" &&
        columns[i].input_type !== "single_select" &&
        columns[i].input_type !== "product_reference" &&
        columns[i].input_type !== "multi_select" &&
        columns[i].input_type !== "numeric" &&
        columns[i].input_type !== "multiline_entry" &&
        columns[i].input_type !== "checkbox" &&
        columns[i].input_type !== "date" &&
        columns[i].input_type !== "toggle" &&
        columns[i].input_type !== "link"
      ) {
        indexesOfNonSupportedValues.push(i);
      }
    }

    indexesOfNonSupportedValues.forEach((idx) => {
      formattedValues.splice(idx, 0, { default_values: null });
    });

    try {
      // If a row is passed in we're editing that row, otherwise we're creating
      // a new row
      if (row) {
        const promises = row.attributes.reduce((acc, attr, index) => {
          acc.push(
            axios.patch(
              `/v2/tenants/${tenant_id}/pim/collections/${collectionID}/rows/attributes/${attr.id}`,
              {
                default_values: rowsToPatch.rows[0][index].default_values
                  ? rowsToPatch.rows[0][index].default_values
                  : [],
              }
            )
          );
          return acc;
        }, [] as Promise<AxiosResponse<any>>[]);
        await Promise.all(promises);
        onSuccess();
        notifySuccess(t("Item edited successfully"));
      } else {
        await axios.patch(
          `/v2/tenants/${tenant_id}/pim/collections/${collectionID}`,
          rowsToPatch
        );
        notifySuccess(t("Item added successfully"));
        setIsLoading(false);
        onSuccess();
      }
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage ? errorMessage : t("There was an error adding values"),
        {
          error,
        }
      );
      setIsLoading(false);
    }
  };

  return (
    <>
      <MarginBottomHeaderLeft>
        <SectionTitle>{row ? t("Edit Item") : t("Add Item")}</SectionTitle>
      </MarginBottomHeaderLeft>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {createForm(columns)}
        <PrimaryButtonFitContainer
          // disabled={!isValid || isLoading}
          loading={isLoading}
          type="submit"
          style={{ marginTop: "10px" }}
        >
          {t("Save")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
}
