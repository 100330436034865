import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { SortingRule } from "react-table";
import useSWR from "swr";
import { useAuthContext } from "../../../../components/Auth";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  PrimaryButtonWithPlusIcon,
} from "../../../../components/Buttons/Buttons";
import { DropDown } from "../../../../components/DropDown/DropDown";
import { DropdownContainer } from "../../../../components/Layout/Layout";
import { Pagination } from "../../../../components/Pagination/Pagination";
import { SearchBar } from "../../../../components/SearchBar/SearchBar";
import { SlideOut } from "../../../../components/SlideOut/SlideOut";
import { StatusLeft } from "../../../../components/Status/Status";
import { Table } from "../../../../components/Table/Table";
import { Flex, Flex2, Flex1 } from "../../../../layout/FormLayout";
import { ContentWrapper } from "../../../../layout/publicPageLayout";
import type {
  DataMutate,
  TeamListResponseSchema,
  TeamSummary,
  UUID,
} from "../../../../types/types";
import { useDebounce } from "../../../../util/hooks";
import {
  useStoreState,
  defaultHandleSort,
  TablePlaceholder,
} from "../../../../util/util";
import type { PIMProduct } from "../../../../types/types.PIM";
import { AddTeamsToProducts } from "./SellerAdminAddTeamsToProducts";
import { useNotifications } from "../../../../components/Notifications/NotificationsContext";
import axios from "axios";
import type { ColumnDef } from "@tanstack/react-table";

type TableData = {
  id: string;
  team_id: string;
  team_name: string;
  number_of_products: number;
  number_of_users: number;
  status: TeamSummary["status"];
};

export function SellerAdminTeamsListByProduct({
  product,
  mutateProduct,
}: {
  product: PIMProduct;
  mutateProduct: DataMutate<PIMProduct>;
}) {
  const { t } = useTranslation();

  const { tenant_id } = useStoreState();

  const [sortingRules, setSortingRules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({
    orderBy: "asc",
  });

  const handleSort = async (rules: SortingRule<object>[]) =>
    defaultHandleSort(rules, sortingRules, setSortingRules, setTableData);

  // searchQuery is loaded from the URL if q exists.
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [offset, setOffset] = useState(0);
  const perPageItems = [10, 20, 50];
  const [perPage, setPerPage] = useState(10);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const { roleIsSellerAdmin } = useAuthContext();
  const { notifyError, notifySuccess } = useNotifications();
  const [showAddTeamToProductSlideOut, setShowAddTeamToProductSlideOut] =
    useState(false);

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  function constructQuery({ baseUrl, query }: any) {
    const paramsWithOffset = new URLSearchParams(
      `offset=${offset}&limit=${perPage}`
    );

    if (query) paramsWithOffset.append("q", query);
    paramsWithOffset.append("order_by", sortingRules.orderBy || "asc");
    if (sortingRules.sortBy) {
      paramsWithOffset.append("sort_by", sortingRules.sortBy);
    }
    return baseUrl + "?" + paramsWithOffset;
  }

  const {
    data: teamsResponse,
    error: teamsError,
    mutate: mutateTeamList,
  } = useSWR<TeamListResponseSchema>(
    constructQuery({
      baseUrl: `/v2/tenants/${tenant_id}/pim/products/${product.id}/teams`,
      query: debouncedSearchQuery,
    })
  );

  const handleRemoveTeam = useCallback(
    (id: UUID) => {
      const removeTeam = async () => {
        try {
          await axios.patch(
            `/v2/tenants/${tenant_id}/pim/products/${product.id}/teams`,
            { team_ids: [id] }
          );
          notifySuccess(t("Team removed successfully"));
          await mutateTeamList();
          await mutateProduct();
        } catch (error) {
          notifyError(t("There was an error removing the team"));
        }
      };
      removeTeam();
    },
    [
      mutateProduct,
      mutateTeamList,
      notifyError,
      notifySuccess,
      product.id,
      t,
      tenant_id,
    ]
  );

  const onAddTeamSuccess = async () => {
    setShowAddTeamToProductSlideOut(false);
    await mutateTeamList();
    await mutateProduct();
  };

  const isLoading = !teamsResponse && !teamsError;

  const can_edit_teams = useMemo(
    () => product.is_editable && roleIsSellerAdmin,
    [product.is_editable, roleIsSellerAdmin]
  );

  const [tableData, setTableData] = useState<TableData[]>([]);
  const tableColumns = React.useMemo<ColumnDef<TableData>[]>(
    () => [
      {
        header: t("Team Name"),
        accessorKey: "team_name",
      },
      {
        header: t("# of users"),
        accessorKey: "number_of_users",
      },
      {
        header: t("# of Products"),
        accessorKey: "number_of_products",
      },
      {
        header: t("Status"),
        accessorKey: "status",
        enableSorting: false,
        align: "left",
        cell: (cell) => (
          <StatusLeft
            color={cell.getValue() === "active" ? "green" : "red"}
            text={cell.getValue() as string}
            textStyle={{ fontSize: "13px" }}
            productStatusType="product"
          />
        ),
      },
      ...(can_edit_teams
        ? [
            {
              header: "",
              accessorKey: " ",
              enableSorting: false,
              cell: ({ row: { original } }: { row: { original: any } }) => (
                <ButtonWithConfirmDialog
                  Button={DeleteButton}
                  testid={"remove-team-from-product"}
                  handleConfirm={() => handleRemoveTeam(original.id)}
                  confirmMessage={t(
                    "Are you sure you want to remove this team?"
                  )}
                />
              ),
            },
          ]
        : []),
    ],
    [handleRemoveTeam, can_edit_teams, t]
  );

  useEffect(() => {
    const handleTeamsData = ({ data, pagination }: TeamListResponseSchema) => {
      setTableData(
        data.reduce<TableData[]>(
          (
            acc,
            { team_id, team_name, number_of_users, number_of_products, status }
          ) =>
            status === "archived"
              ? acc
              : [
                  ...acc,
                  {
                    id: team_id,
                    team_id,
                    team_name,
                    number_of_users,
                    number_of_products,
                    status,
                  },
                ],
          []
        )
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };

    if (teamsResponse) {
      const { data: products, pagination } = teamsResponse;
      handleTeamsData({ data: products, pagination });
    }
  }, [teamsResponse, perPage]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setOffset(0);
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  return (
    <>
      <Flex style={{ marginTop: "30px" }}>
        <Flex2>
          <SearchBar
            query={searchQuery}
            placeHolder={t("Search by Team Name")}
            handleChange={handleSearch}
            handleClearInput={handleClearSearch}
            maxWidth="450px"
          />
        </Flex2>
        <Flex1 style={{ textAlign: "right" }}>
          <div
            data-alignright
            style={{ display: "flex", justifyContent: "end" }}
          >
            {can_edit_teams && (
              <PrimaryButtonWithPlusIcon
                onClick={() => setShowAddTeamToProductSlideOut(true)}
                style={{ padding: "8px 12px" }}
              >
                {t("Add")}
              </PrimaryButtonWithPlusIcon>
            )}
            <SlideOut
              show={showAddTeamToProductSlideOut}
              closeFlyout={() => setShowAddTeamToProductSlideOut(false)}
            >
              <AddTeamsToProducts
                productID={product.id}
                onSuccess={onAddTeamSuccess}
                onCancel={() => setShowAddTeamToProductSlideOut(false)}
              />
            </SlideOut>
          </div>
        </Flex1>
      </Flex>

      <DropdownContainer>
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          textLeft={t("items") + ":"}
          textRight={t(" Per Page")}
          direction={"right"}
          className={"per_Page"}
          clickHandler={changePerPage}
        ></DropDown>
      </DropdownContainer>
      <ContentWrapper>
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={isLoading}
          error={teamsError}
          lastChildleftAlign={!can_edit_teams}
          // rowClick={handleRowClick}
          handleSort={handleSort}
          Placeholder={
            <TablePlaceholder
              message={
                !!teamsError
                  ? t(
                      "There was an error fetching customers. Please try again later."
                    )
                  : t(
                      "No items to show. Please click the add button to add teams."
                    )
              }
            />
          }
        />
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </ContentWrapper>
    </>
  );
}
