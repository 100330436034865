import type { AxiosError } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import useSWR from "swr";
import { useAuthContext } from "../../../../../components/Auth";
import {
  GoBackButtonSmall,
  PrimaryButtonWithPlusIcon,
} from "../../../../../components/Buttons/Buttons";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { Table } from "../../../../../components/Table/Table";
import { PageWrapper } from "../../../../../layout/portalPageLayout";
import { ContentWrapper } from "../../../../../layout/publicPageLayout";
import type { AttributeTemplateSchema } from "../../../../../types/types.PIM";

import { useRoutePath } from "../../../../../util/Routing";
import { toTitleCase, useStoreState } from "../../../../../util/util";
import { NameWithSystemDefaultIcon } from "../components/PIM.components.util";
import { AddCollectionToTemplateForm } from "./AddCollectionToTemplateForm";
import { TemplatesNav } from "./TemplatesNav";
import type { ColumnDef } from "@tanstack/react-table";

export function SellerAdminTemplateCollectionTable() {
  const { template_id } = useParams<{ template_id: string }>();

  const [tableData, setTableData] = useState<
    {
      id: string;
      name: { name: string; is_system: boolean; id: string };
      attribute_count: number;
      mandatory: "yes" | "no";
      restricted: "yes" | "no";
    }[]
  >([]);

  const { hasPermission } = useAuthContext();
  const [showCreateSlideOut, setCreateSlideOut] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { adminPath } = useRoutePath();

  const {
    data: template,
    error: templateFetchError,
    mutate: mutateCollections,
  } = useSWR<AttributeTemplateSchema, AxiosError>(
    `/v2/tenants/${tenant_id}/pim/templates/${template_id}`
  );

  const tableColumns = useMemo<
    ColumnDef<{
      id: string;
      name: { name: string; is_system: boolean; id: string };
      attribute_count: number;
      mandatory: "yes" | "no";
      restricted: "yes" | "no";
    }>[]
  >(() => {
    const columns: ColumnDef<{
      id: string;
      name: { name: string; is_system: boolean; id: string };
      attribute_count: number;
      mandatory: "yes" | "no";
      restricted: "yes" | "no";
    }>[] = [
      {
        header: t("Collection Name"),
        accessorKey: "name",
        cell: (cell) => (
          <NameWithSystemDefaultIcon
            name={(cell.getValue() as { name: string }).name}
            id={(cell.getValue() as { id: string }).id}
            is_system={(cell.getValue() as { is_system: boolean }).is_system}
          />
        ),
      },
      { header: t("Display Name"), accessorKey: "display_name" },
      {
        header: t("No. of Attributes"),
        accessorKey: "attribute_count",
      },
      {
        header: t("Items"),
        accessorKey: "item_count",
      },
      {
        header: t("Restricted"),
        accessorKey: "restricted",
        cell: (props) => (
          <div>{toTitleCase(props.getValue() as "yes" | "no")}</div>
        ),
      },
    ];

    if (template?.show_mandatory_completion_score) {
      columns.push({
        header: t("Mandatory"),
        accessorKey: "mandatory",
        cell: (props) => (
          <div>{toTitleCase(props.getValue() as "yes" | "no")}</div>
        ),
      });
    }

    return columns;
  }, [t, template?.show_mandatory_completion_score]);

  const isLoading = !template && !templateFetchError;

  const handleRowClick = (e: React.MouseEvent) => {
    history.push(
      `${adminPath}/pim/templates/${template_id}/collections/${e?.currentTarget?.id}`
    );
  };

  const closeCreateSlideOut = () => setCreateSlideOut(false);
  const openCreateSlideOut = () => setCreateSlideOut(true);

  const onCollectionCreationSuccess = () => {
    mutateCollections();
    closeCreateSlideOut();
  };

  const isDefaultTemplate = template?.template_name === "Default Template";
  useEffect(() => {
    if (template) {
      setTableData(
        template.collections.map(
          (
            {
              id,
              name,
              attribute_count,
              display_name,
              is_system,
              is_restricted,
              is_mandatory,
            },
            index
          ) => ({
            id,
            name: { name: name, id: id, is_system: is_system },
            attribute_count,
            display_name: display_name ? t([display_name]) : "",
            item_count: template.collections[index]?.rows?.length ?? 0,
            mandatory: is_mandatory ? "yes" : "no",
            restricted: is_restricted ? "yes" : "no",
          })
        )
      );
    }
  }, [t, template]);

  const addCollectionTitle = isDefaultTemplate
    ? t("Cannot add collections to default template")
    : undefined;

  return (
    <PageWrapper>
      <div style={{ width: "fit-content" }}>
        <Link
          to={{
            pathname: `${adminPath}/pim/templates`,
            state: location.state, // Preserve the state when going back
          }}
        >
          <GoBackButtonSmall text={"Back"} />
        </Link>
      </div>
      <TemplatesNav
        pageTitle={template?.template_name ?? "Template"}
        tabIndex={1}
        templateID={template_id}
      />
      <SlideOut closeFlyout={closeCreateSlideOut} show={showCreateSlideOut}>
        <AddCollectionToTemplateForm
          templateID={template_id}
          isTemplateInUse={template?.is_frozen}
          onSuccess={onCollectionCreationSuccess}
        />
      </SlideOut>
      <ContentWrapper>
        <Table
          columns={tableColumns}
          isLoading={isLoading}
          error={templateFetchError}
          data={tableData}
          rowClick={handleRowClick}
          lastChildleftAlign
        />
        {hasPermission("modify_templates") && (
          <PrimaryButtonWithPlusIcon
            style={{ marginTop: "24px" }}
            onClick={openCreateSlideOut}
            disabled={isDefaultTemplate}
            title={addCollectionTitle}
          >
            {t("Add Collection")}
          </PrimaryButtonWithPlusIcon>
        )}
      </ContentWrapper>
    </PageWrapper>
  );
}
