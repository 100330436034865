import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import styled from "styled-components/macro";
import { match } from "ts-pattern";
import { useAuthContext } from "../../../../components/Auth";
import {
  DocumentIcon,
  ImageIcon,
  LinkIcon,
  MagicDocIcon,
  VideoIcon,
} from "../../../../components/Icons/Icons";
import type { SupportedAssetType } from "../../../../types/types.PIM";
import {
  GetDocumentIcon,
  TrimmedName,
  get_content_type,
} from "../../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";
import type { AnalyticsProp } from "../../../../types/types";

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  overflow: hidden;
`;

const LinkWrapper = styled.a`
  width: fit-content;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryTextColor};
  &:hover {
    text-decoration: underline;
  }
`;

const LinkAnchor = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryTextColor};
  &:hover {
    text-decoration: underline;
  }
`;

export const DocPortfolioName = styled.span<{ disabled: boolean }>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.disabledButtonTextColor : theme.primaryTextColor};
`;

export const TableContainer = styled.div<{ lastChild?: boolean }>`
  padding-bottom: 40px;
  & div[class*="TableWrapper"] {
    overflow: visible;
    td {
      overflow: visible;
    }
  }
  border-bottom: ${({ theme, lastChild }) =>
    lastChild ? "none" : `1px solid ${theme.secondaryBorder}`};
`;

export const AssetNameIcon = ({
  asset_type,
  signed_url,
  name,
  is_downloadable,
  is_generated,
  content_type,
}: {
  asset_type: SupportedAssetType;
  signed_url: string;
  name: string;
  is_downloadable: boolean;
  is_generated: boolean;
  content_type: string | null;
}) => {
  const { roleIsGuest } = useAuthContext();
  const { t } = useTranslation();
  const isDisabled = roleIsGuest && !is_downloadable;
  return (
    <FlexWrapper>
      <div>
        {match(asset_type)
          .with("image", () => <ImageIcon />)
          .with("document", () =>
            is_generated ? (
              <div
                data-tip={t("Generated Document")}
                data-for="generated-doc-tooltip"
              >
                <MagicDocIcon />
                <ReactTooltip effect="solid" id="generated-doc-tooltip" />
              </div>
            ) : (
              <GetDocumentIcon
                asset_type={asset_type}
                file_type={get_content_type(content_type ?? "application/pdf")}
              />
            )
          )
          .with("video", () => <VideoIcon />)
          .otherwise(() => (
            <DocumentIcon />
          ))}
      </div>
      {isDisabled ? (
        <>
          <DocPortfolioName
            data-tip={match(asset_type)
              .with("image", () => t("Register to access this image"))
              .with("document", () => t("Register to access this document"))
              .with("video", () => t("Register to access this video"))
              .otherwise(() => (
                <DocumentIcon />
              ))}
            data-for={`${name}-doc-tooltip`}
            disabled={true}
          >
            {name}
          </DocPortfolioName>
          <ReactTooltip effect="solid" id={`${name}-doc-tooltip`} />
        </>
      ) : (
        <LinkWrapper
          href={signed_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TrimmedName text={name} />
        </LinkWrapper>
      )}
    </FlexWrapper>
  );
};

export const AssetLink = ({
  url,
  name,
  is_visible,
  analytics,
}: {
  url: string;
  name: string;
  is_visible: boolean;
  analytics?: AnalyticsProp;
}) => {
  const { roleIsGuest } = useAuthContext();
  const { t } = useTranslation();
  const isDisabled = roleIsGuest && !is_visible;
  return (
    <div>
      <FlexWrapper>
        <LinkIcon />
        {isDisabled ? (
          <>
            <DocPortfolioName
              data-tip={t("Register to access this link")}
              data-for={`${name}-link-tooltip`}
              disabled={true}
            >
              {name}
            </DocPortfolioName>
            <ReactTooltip effect="solid" id={`${name}-link-tooltip`} />
          </>
        ) : (
          <LinkAnchor href={url} target="_blank" rel="noopener noreferrer">
            <TrimmedName analytics={analytics} text={name} />
          </LinkAnchor>
        )}
      </FlexWrapper>
    </div>
  );
};
