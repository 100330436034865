import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { SortingRule } from "react-table";
import useSWR from "swr";
import { Pagination } from "../../../../components/Pagination/Pagination";
import { Table } from "../../../../components/Table/Table";
import { endpoints } from "../../../../endpoints";
import { ContentWrapper } from "../../../../layout/publicPageLayout";
import type { WithPagination } from "../../../../types/types";
import type { ProductListPriceSchema } from "../../../../types/types.PIM";
import {
  defaultHandleSort,
  formatDate,
  rowHover,
  TablePlaceholder,
  useStoreState,
} from "../../../../util/util";
import type { ColumnDef } from "@tanstack/react-table";

type TableData = ProductListPriceSchema & { sku_name: string };

export const ProductPricingPortfolioTab = ({
  product_id,
}: {
  product_id: string;
}) => {
  const per_page = 20;
  const [table_data, set_table_data] = useState<TableData[]>([]);
  const [offset, set_offset] = useState(0);
  const [sorting_rules, set_sorting_rules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({ orderBy: "asc" });
  const [table_pagination, set_table_pagination] = useState({
    perPage: per_page,
    pageCount: 0,
    pageIndex: 0,
  });
  const { tenant_id } = useStoreState();
  const { t } = useTranslation();
  const { data: product_list_prices, error: product_list_prices_error } =
    useSWR<WithPagination<{ data: ProductListPriceSchema[] }>>([
      endpoints.v2_tenants_tenant_id_products_product_id_list_prices(
        tenant_id,
        product_id
      ),
      useMemo(
        () => ({
          params: {
            offset,
            limit: per_page,
            order_by: sorting_rules.orderBy || "asc",
            ...(sorting_rules.sortBy && { sort_by: sorting_rules.sortBy }),
          },
        }),
        [offset, sorting_rules.orderBy, sorting_rules.sortBy]
      ),
    ]);

  const handle_sort = async (rules: SortingRule<ProductListPriceSchema>[]) => {
    defaultHandleSort(rules, sorting_rules, set_sorting_rules, set_table_data);
  };

  const change_page = (offset: number) => {
    set_offset(offset);
    set_table_data([]);
  };

  const table_columns = useMemo<ColumnDef<TableData>[]>(
    () => [
      {
        header: t("SKU"),
        accessorKey: "sku_name",
        enableSorting: false,
        cell: (cell) => cell.renderValue(),
      },
      {
        header: t("Min. No. of Units"),
        accessorKey: "minimum_units",
        cell: (cell) => cell.renderValue(),
      },
      {
        header: t("Min. Order Quantity"),
        accessorKey: "quantity",
        cell: (cell) => cell.renderValue(),
      },
      {
        header: t("Price ($/UoM)"),
        accessorKey: "price",
        cell: (cell) => cell.renderValue(),
      },
      {
        header: t("Valid Until"),
        accessorKey: "valid_until",
        cell: (cell) => cell.renderValue(),
      },
    ],
    [t]
  );

  useEffect(() => {
    const handle_pricing_data = ({
      data,
      pagination,
    }: WithPagination<{ data: ProductListPriceSchema[] }>) => {
      set_table_data(
        data
          .filter(({ sku }) => sku)
          .reduce<TableData[]>(
            (acc, { valid_until, sku, ...rest }) => [
              ...acc,
              {
                ...rest,
                sku,
                valid_until: formatDate(valid_until, true),
                sku_name: sku
                  ? `${sku.packaging_type.name} (${sku.package_volume} ${sku.packaging_unit.name})`
                  : "",
              },
            ],
            []
          )
      );
      set_table_pagination({
        perPage: per_page,
        pageCount: Math.ceil(pagination.total / per_page),
        pageIndex: pagination.offset / per_page + 1,
      });
    };
    if (product_list_prices) {
      handle_pricing_data(product_list_prices);
    }
  }, [per_page, product_list_prices]);

  const is_loading_data = !product_list_prices && !product_list_prices_error;
  return (
    <ContentWrapper style={{ marginTop: "32px" }}>
      <Table
        columns={table_columns}
        data={table_data}
        lastChildleftAlign
        isLoading={is_loading_data}
        handleSort={handle_sort}
        error={product_list_prices_error}
        rowHover={rowHover}
        Placeholder={<TablePlaceholder message={t("No items to show.")} />}
      />
      <Pagination
        pagination={table_pagination}
        offset={offset}
        handlePageClick={change_page}
        miniPagination={true}
      />
    </ContentWrapper>
  );
};
