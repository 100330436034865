import type { ChangeEvent } from "react";
import React, { useContext, useEffect, useState } from "react";
import { SearchBar } from "../../../components/SearchBar/SearchBar";
import {
  ContentWrapper,
  FiltersWrapper,
  HorizontalButtonsContainer,
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../layout/portalPageLayout";
import { DropDown } from "../../../components/DropDown/DropDown";
import { FiltersDropDown } from "../../../components/FiltersDropDown/FiltersDropDown";
import { Pagination } from "../../../components/Pagination/Pagination";
import { Table } from "../../../components/Table/Table";
import { useDebounce } from "../../../util/hooks";
import { useHistory } from "react-router";
import { StringParam } from "serialize-query-params";
import { ArrayParam, NumberParam, useQueryParams } from "use-query-params";
import { useRoutePath } from "../../../util/Routing";
import useSWR from "swr";
import {
  convertStringArrayToObj,
  formatDateTime,
  makeUrlWithParams,
  useInAppNotifications,
  rowHover,
  useStoreState,
} from "../../../util/util";
import { endpoints } from "../../../endpoints";
import type {
  SampleRequest,
  SampleRequestDetail,
  SampleRequestPaginatedOutput,
  SampleRequestStatus,
  StatusAndId,
  User,
} from "../../../types/types";
import { IDMobile } from "../../../components/IDs/IDs";
import { StatusRight } from "../../../components/Status/Status";
import { Auth } from "../../../components/Auth";
import { useTranslation } from "react-i18next";
import {
  TooltipCell,
  update_status_string_list,
} from "../../Buyer/BuyerOrders/BuyerOrders.util";
import ReactTooltip from "react-tooltip";
import {
  PrimaryButtonWithPlusIcon,
  SecondaryButtonMedium,
} from "../../../components/Buttons/Buttons";
import { Modal } from "../../../components/Modal/Modal";
import { SellerCreateSampleRequest } from "../../Seller/SellerCreateSampleRequest/SellerCreateSampleRequest";
import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import { DownloadReport } from "../SellerOrdersListPage/DownloadReport/DownloadReport";
import styled from "styled-components/macro";
import { TrimmedName } from "../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";
import type { ColumnDef } from "@tanstack/react-table";

const TableWrapper = styled.div<{ is_seller: boolean }>`
  table {
    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(5) {
      max-width: ${({ is_seller }) => (is_seller ? "140px" : "unset")};
    }
  }
`;

type SampleRequestRow = {
  // The `id` is not shown, but is needed to handle clicks on the row.
  id: string;
  number: string;
  from: string;
  products: { items: SampleRequestDetail[]; id: string };
  requested_date: string;
  last_activity: string;
  email: string;
  company_name: string;
  phone_number: string;
  destination: string;
  status: StatusAndId<SampleRequestStatus>;
  unread: boolean;
};

type StatusItem = {
  color: string;
  text: string;
  value: SampleRequestStatus;
};

// TODO can this be combined with a different status function?
export const SampleRequestStatusLabel = ({
  status,
  id,
  t,
}: {
  status: string;
  id?: string;
  t: (s: string) => string;
}) => {
  switch (status) {
    case "requested":
      return <StatusRight color="blue" text={t("New")} id={id} />;
    case "accepted":
      return <StatusRight color="green" text={t("Accepted")} id={id} />;
    case "rejected":
      return <StatusRight color="red" text={t("Declined")} id={id} />;
    case "cancelled":
      return <StatusRight color="red" text={t("Cancelled")} id={id} />;
    case "in_progress":
      return <StatusRight color="fullBlue" text={t("In Progress")} id={id} />;
    case "pending":
    case "pending_activation":
      return (
        <StatusRight color="gray" text={t("Pending Activation")} id={id} />
      );
    case "completed":
      return <StatusRight color="green" text={t("Completed")} id={id} />;
    case "shipped":
      return <StatusRight color="blue" text={t("Shipped")} id={id} />;
    default:
      return <StatusRight color="gray" text={status} id={id} />;
  }
};

const getProductsAsText = (
  items: SampleRequestDetail[],
  t: (s: string) => string
) => {
  if (items.length === 1) {
    return `${items[0].product.name}`;
  }
  if (items.length > 1) {
    return `${items[0].product.name} + ${items.length - 1} ${t("more")}`;
  }
  return `--`;
};

const statusList = (
  t: (val: string) => string,
  roleIsSomeKindOfSeller: boolean
): StatusItem[] => [
  { color: "blue", text: t("New"), value: "requested" },
  { color: "green", text: t("Accepted"), value: "accepted" },
  { color: "fullBlue", text: t("In Progress"), value: "in_progress" },
  { color: "fullBlue", text: t("Shipped"), value: "shipped" },
  { color: "green", text: t("Completed"), value: "completed" },
  ...(roleIsSomeKindOfSeller
    ? [
        {
          color: "gray",
          text: t("Pending Activation"),
          value: "pending_activation" as SampleRequestStatus,
        },
      ]
    : []),
  { color: "red", text: t("Declined"), value: "rejected" },
  { color: "red", text: t("Cancelled"), value: "cancelled" },
];
const getSampleRequestItemsList = (items: SampleRequestDetail[]) => {
  if (items && items.length > 1) {
    return (
      <ul>
        {items.map((item, index) => (
          <li key={index}>{item.product.name}</li>
        ))}
      </ul>
    );
  }
  return null;
};

/**
 * Shows a list of sample requests. Used for producer sellers and seller admins and buyers.
 */
export const SampleRequestListPage = () => {
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
    status: ArrayParam,
  });

  // const t = user.rbac_role === "buyer_admin" || user.rbac_role === useTranslation.t

  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const { user } = useContext(Auth);
  const { samplesNotifications } = useInAppNotifications(
    storefront_id,
    user as User
  );
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const [perPage, setPerPage] = useState(query?.perPage ?? 10);
  const perPageItems = [10, 20, 50];
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const [showCreateSampleRequestModal, setShowCreateSampleRequestModal] =
    useState(false);
  const [showDialogBox, setShowDialogBox] = useState(false);

  const [downloadReportSlideOut, setDownloadReportSlideOut] = useState(false);

  const history = useHistory();
  const { accountPath } = useRoutePath();

  const { roleIsSomeKindOfSeller, roleIsSomeKindOfBuyer } = useContext(Auth);

  const [selectedStatus, setSelectedStatus] = useState<StatusItem[]>([]);
  const [selectedStatusList, setSelectedStatusList] = useState<string[]>(
    (query?.status?.filter((status) => !!status) as string[]) ?? []
  );

  const {
    data: sampleRequestsResponse,
    error: sampleRequestsError,
    mutate: mutateSampleRequests,
  } = useSWR<SampleRequestPaginatedOutput>(
    makeUrlWithParams(
      endpoints.v1_storefronts_id_sampleRequests(storefront_id),
      {
        source: "logged_in",
        q: debouncedSearchQuery || null,
        order_by: "desc",
        offset: offset,
        limit: perPage,
        status: roleIsSomeKindOfSeller
          ? update_status_string_list(selectedStatusList)
          : selectedStatusList,
      }
    )
  );

  const isLoading = !sampleRequestsResponse && !sampleRequestsError;

  const [tableData, setTableData] = useState<SampleRequestRow[]>([]);
  /**
   * When adding a new column, make sure to update the TableWrapper styled component above.
   * The `Products`, `From`, `Email` and `Destination` columns have a max-width of 140px, for `roleIsSomeKindOfSeller`.
   */
  const tableColumns = React.useMemo<ColumnDef<SampleRequestRow>[]>(() => {
    return [
      {
        header: t("Sample ID"),
        accessorKey: "number",
        cell: (cell) => <IDMobile>{`${cell.getValue()}`}</IDMobile>,
      },
      {
        header: t("Products"),
        accessorKey: "products",
        cell: (cell) => {
          const cellValue = cell.getValue() as {
            items: SampleRequestDetail[];
            id: string;
          };
          return (
            <TooltipCell data-for={`${cellValue.id}-tooltip`} data-tip={""}>
              <TrimmedName text={getProductsAsText(cellValue.items, t)} />
              <ReactTooltip
                id={`${cellValue.id}-tooltip`}
                place="top"
                data-html={true}
                effect="solid"
                backgroundColor="#60676F"
                multiline={true}
              >
                {getSampleRequestItemsList(cellValue.items)}
              </ReactTooltip>
            </TooltipCell>
          );
        },
      },

      ...(roleIsSomeKindOfBuyer
        ? [
            {
              header: t("Sample Requested"),
              accessorKey: "requested_date",
            },
            {
              header: t("Last Activity"),
              accessorKey: "last_activity",
            },
          ]
        : roleIsSomeKindOfSeller
        ? [
            {
              header: t("From"),
              accessorKey: "from",
            },
            {
              header: t("Company Name"),
              accessorKey: "company_name",
            },
            {
              header: t("Last Activity"),
              accessorKey: "last_activity",
            },
          ]
        : // Should never happen.
          []),

      {
        header: t("Destination"),
        accessorKey: "destination",
      },
      {
        header: t("Status"),
        accessorKey: "status",
        width: 50,
        minWidth: 50,
        align: "right",
        cell: (cell) => {
          return (
            <SampleRequestStatusLabel
              status={(cell.getValue() as StatusAndId<string>).status}
              id={(cell.getValue() as StatusAndId<string>).id}
              t={t}
            />
          );
        },
      },
    ];
  }, [roleIsSomeKindOfBuyer, roleIsSomeKindOfSeller, t]);

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const applyStatus = (items: StatusItem[]) => {
    setSelectedStatus(items);
    setSelectedStatusList(items.map((item) => item.value));
    changePage(0);
  };

  useEffect(() => {
    setQuery({ offset, perPage, status: selectedStatusList });
    const statusObj = convertStringArrayToObj(selectedStatusList);
    setSelectedStatus(
      statusList(t, roleIsSomeKindOfSeller).filter(
        (item) => !!statusObj[item.value]
      )
    );
  }, [
    offset,
    perPage,
    roleIsSomeKindOfSeller,
    selectedStatusList,
    setQuery,
    t,
  ]);

  useEffect(() => {
    const getUnread = (sampleRequest: SampleRequest): boolean => {
      return samplesNotifications?.ids
        ? samplesNotifications?.ids.includes(sampleRequest?.id)
        : false;
    };

    // Populate the table with data when the response comes back.
    if (sampleRequestsResponse) {
      const { data, pagination } = sampleRequestsResponse;

      const newTableData = data.map(
        (sampleRequest): SampleRequestRow => ({
          id: sampleRequest.id || "--",
          number: sampleRequest.number || "--",
          from: sampleRequest.buyer_company_name || "--",
          products: { items: sampleRequest.items, id: sampleRequest.id },

          // TODO: at time of writing `created_at` is when the cart was first
          // created (first item added to it), not when the cart was submitted.
          // Issa is aware it needs to be changed.
          requested_date: formatDateTime(sampleRequest.created_at) || "--",

          last_activity: formatDateTime(sampleRequest.modified_at) || "--",
          email: sampleRequest.shipping_address?.email_address || "--",
          company_name: sampleRequest.shipping_address?.company_name || "--",
          phone_number: sampleRequest.shipping_address?.phone_number || "--",
          // TODO: handle international city/state?
          destination: `${sampleRequest.shipping_address?.city || "--"}, ${
            sampleRequest.shipping_address?.state || "--"
          }`,
          status: {
            status: sampleRequest.status || "--",
            id: sampleRequest.id,
          },
          unread: getUnread(sampleRequest),
        })
      );
      setTableData(newTableData);

      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    }
  }, [sampleRequestsResponse, setTableData, perPage, samplesNotifications]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const closeDownloadReportSlideout = () => setDownloadReportSlideOut(false);

  useEffect(() => {
    if (debouncedSearchQuery === "") {
      setQuery({ q: undefined });
    }
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, query, debouncedSearchQuery]);

  const handleRowClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    const params = new URLSearchParams();
    if (query.q) {
      params.append("q", query.q);
    }
    params.append("offset", String(offset));
    params.append("perPage", String(perPage));
    ((query?.status ?? []).filter((status) => !!status) as string[]).forEach(
      (status) => params.append("status", status)
    );
    history.push(`${accountPath}/samples/${e.currentTarget.id}?${params}`);
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>{t("Samples")}</PageTitle>
        <SearchBar
          query={searchQuery}
          placeHolder={t("Search by Product, Customer, or ID")}
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
        {roleIsSomeKindOfSeller && (
          <HorizontalButtonsContainer>
            <SecondaryButtonMedium
              onClick={() => setDownloadReportSlideOut(true)}
            >
              {t("Download Report")}
            </SecondaryButtonMedium>
            <PrimaryButtonWithPlusIcon
              onClick={() => setShowCreateSampleRequestModal(true)}
            >
              {t("Create New")}
            </PrimaryButtonWithPlusIcon>
          </HorizontalButtonsContainer>
        )}
      </PageHeader>
      <Modal
        show={showCreateSampleRequestModal}
        closeModal={() => setShowDialogBox(true)}
        modalWidth={"95%"}
      >
        <SellerCreateSampleRequest
          onSuccess={(sampleID) => {
            setShowCreateSampleRequestModal(false);
            mutateSampleRequests();
            history.push(`${accountPath}/samples/${sampleID}`);
          }}
        />
      </Modal>
      <ConfirmDialog
        show={showDialogBox}
        confirmMessage={t(
          "Are you sure you want to cancel this sample request?"
        )}
        closeDialog={() => setShowDialogBox(false)}
        handleConfirm={() => {
          setShowCreateSampleRequestModal(false);
          setShowDialogBox(false);
        }}
      />

      <FiltersWrapper>
        <FiltersDropDown
          activeItems={selectedStatus}
          applyStatus={applyStatus}
          list={statusList(t, roleIsSomeKindOfSeller)}
        />
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          showIcon
          hideBorder
          textLeft={t("items") + ":"}
          textRight={t(" Per Page")}
          direction={"left"}
          clickHandler={changePerPage}
        />
      </FiltersWrapper>
      <ContentWrapper>
        <TableWrapper is_seller={roleIsSomeKindOfSeller}>
          <Table
            columns={tableColumns}
            data={tableData}
            isLoading={isLoading}
            error={sampleRequestsError}
            rowClick={handleRowClick}
            rowHover={rowHover}
          />
        </TableWrapper>
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        ></Pagination>
        <SlideOut
          closeFlyout={closeDownloadReportSlideout}
          show={downloadReportSlideOut}
        >
          <DownloadReport
            closeSlideout={closeDownloadReportSlideout}
            transactionType={"sample"}
          />
        </SlideOut>
      </ContentWrapper>
    </PageWrapper>
  );
};
